import React from "react";
import styled from "styled-components";

const Header = () => {
	const Container = styled.div`
		background-color: #c0392b;
		height: 80px;
		position: relative;
		z-index: 1;

		h1 {
			text-align: center;
			margin: 0px;
			color: #ffffff;
			padding: 20px;
			box-sizing: border-box;
			height: 80px;
			display: flex;
			align-items: center;
		}
	`;

	return (
		<Container>
			<h1>Mein Reddit</h1>
		</Container>
	);
};

export default Header;
