import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const SideBar = () => {
	// Styled components
	const Container = styled.div`
		width: 12%;
		background-color: #2c3e50;
		height: calc(100vh);
		position: fixed;
		top: 0px;

		.side-bar-items {
			position: relative;
			top: 70px;
			margin-top: 20px;
		}
	`;

	const SideBarItem = styled.div`
		text-align: left;
		padding: 10px 20px;
		a,
		i {
			color: #ffffff;
			font-size: 20px;
		}

		a {
			transition: color 0.3s;

			&:hover {
				color: #c0392b;
			}
		}

		i {
			padding-right: 10px;
		}
	`;

	return (
		<Container>
			<div className="side-bar-items">
				{sidebarData.map((item) => (
					<SideBarItem>
						<i className={item.icon}></i>
						<Link to={item.link}>{item.title}</Link>
					</SideBarItem>
				))}
			</div>
		</Container>
	);
};

// Static data for our side bar items
const sidebarData = [
	{
		title: "Home",
		icon: "fas fa-home",
		link: "/",
	},
	{
		title: "Cryptocurrencies",
		icon: "fab fa-ethereum",
		link: "/category/cryptocurrencies",
	},
];

export default SideBar;
