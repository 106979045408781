import React from "react";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
import "./App.css";

// Import components
import SideBar from "./components/sidebar";
import Header from "./components/header";
import Home from "./components/home";

// Import pages
import CryptoCurrenciesPage from "./pages/cryptocurrencies";

function App() {
	// Styled components
	const BodyWrapper = styled.div`
		margin: 0px 50px 50px 50px;
		max-width: 80%;
		width: 80%;
		position: relative;
		left: 12%;
	`;

	const Wrap = styled.div`
		display: flex;
	`;

	return (
		<div className="App">
			<Header />
			<Wrap>
				<SideBar />
				<BodyWrapper>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route
							exact
							path="/category/cryptocurrencies"
							component={CryptoCurrenciesPage}
						/>
					</Switch>
				</BodyWrapper>
			</Wrap>
		</div>
	);
}

export default App;
