import React from "react";
import styled from "styled-components";

const Card = (props) => {
	const CardContainer = styled.div`
		background-color: #ffffff;
		padding: 20px;
		box-sizing: border-box;
		border-radius: 5px;
		-webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);

		h2 {
			font-size: 16px;
			text-align: center;
		}
	`;

	return (
		<CardContainer>
			<img src={props.thumbnail} />
			<h2>{props.title}</h2>
		</CardContainer>
	);
};

export default Card;
