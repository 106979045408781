import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Card from "./card";
import StatCard from "./stat-card";

const CardGrid = (props) => {
	const Grid = styled.div`
		display: grid;
		grid-template-columns: repeat(${props.columns}, ${props.width});
		grid-column-gap: 20px;
		grid-row-gap: 20px;
	`;

	return (
		<Grid>
			{props.data.map((item) => (
				<div>
					{props.type !== "stat" ? (
						<Card title={item.title} thumbnail={item.thumbnail} />
					) : (
						<div>
							<StatCard
								key={item.id}
								title={item.name}
								thumbnail={item.image}
								currentPrice={item.currentPrice}
								redditLink={item.subreddit_url}
								homePage={item.link}
							/>
						</div>
					)}
				</div>
			))}
		</Grid>
	);
};

export default CardGrid;
