import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import CardGrid from "../components/card-grid";

const CryptoCurrenciesPage = () => {
	const [loading, setLoading] = useState("Loading...");
	const [data, setData] = useState([]);

	useEffect(async () => {
		const cryptoData = [];
		for (let i = 0; i < cryptocurrencies.length; i++) {
			let currentItem = {};
			const getData = await axios
				.get(`https://api.coingecko.com/api/v3/coins/${cryptocurrencies[i]}`)
				.then((res) => {
					currentItem.id = res.data.id;
					currentItem.symbol = res.data.symbol;
					currentItem.name = res.data.name;
					currentItem.description = res.data.description.en;
					currentItem.link = res.data.links.homepage[0];
					currentItem.reddit = res.data.links.subreddit_url;
					currentItem.image = res.data.image.large;
					currentItem.originDate = res.data.genesis_date;
					currentItem.currentPrice = res.data.market_data.current_price.nzd;
				});
			cryptoData.push(currentItem);
		}

		setData(cryptoData);
	}, []);

	return (
		<div>
			<h2>Cryptocurrencies</h2>
			{data.length <= 0 ? (
				loading
			) : (
				<CardGrid columns="4" width="25%" data={data} type="stat"></CardGrid>
			)}
		</div>
	);
};

const cryptocurrencies = [
	"bitcoin",
	"ethereum",
	"binancecoin",
	"ripple",
	"tether",
	"polkadot",
	"cardano",
	"uniswap",
	"litecoin",
	"chainlink",
	"theta-token",
	"bitcoin-cash",
	"stellar",
	"usd-coin",
	"filecoin",
	"tron",
	"wrapped-bitcoin",
	"dogecoin",
	"vechain",
	"solana",
];

export default CryptoCurrenciesPage;
