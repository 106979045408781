import React, { useEffect, useState } from "react";
import axios from "axios";

import CardGrid from "./card-grid";

// Import media files
import coinIcon from "../assets/images/dogge_icon.jpg";

const Home = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState("Loading...");

	useEffect(() => {
		axios.get("https://www.reddit.com/r/CryptoCurrency.json").then((res) => {
			const data = res.data.data.children;
			const processedData = [];
			for (let i = 0; i < data.slice(0, 10).length; i++) {
				let currentItem = {};
				currentItem.id = data[i].data.id;
				currentItem.title = data[i].data.title;
				currentItem.thumbnail =
					data[i].data.thumbnail != "self" ? data[i].data.thumbnail : coinIcon;
				currentItem.content = data[i].data.content;
				currentItem.permalink = data[i].data.permalink;
				currentItem.url = data[i].data.url;
				processedData.push(currentItem);
			}

			setData(processedData);
		});
	}, []);

	return (
		<div>
			<h2>Featured Articles</h2>
			{data.length <= 0 ? (
				loading
			) : (
				<CardGrid columns="3" width="33%" data={data}></CardGrid>
			)}
		</div>
	);
};

export default Home;
